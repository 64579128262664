import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { styled } from 'styles';
import Input, { InputProps } from 'components/Input/Input';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { DayPickerInputProps } from 'react-day-picker/types/Props';

const Day = (day, modifiers) => (
  <div className={clsx('day', { ...modifiers, past: dayjs(day).isBefore(dayjs(), 'day') })}>
    {dayjs(day).format('DD')}
  </div>
);

const Navbar = ({ month, onPreviousClick, onNextClick }) => (
  <StyledNavbar>
    <Button className="prev" simple type="button" onClick={() => onPreviousClick()}>
      <Icon name="arrow-simple" size={16} />
    </Button>
    <div className="month">{dayjs(month).format('MMMM, YYYY')}</div>
    <Button className="next" simple type="button" onClick={() => onNextClick()}>
      <Icon name="arrow-simple" size={16} />
    </Button>
  </StyledNavbar>
);

interface InputDateProps extends Omit<DayPickerInputProps, 'onChange'> {
  className?: string;
  format?: string;
  inputProps: InputProps;
  onChange?: (date: string) => void;
}

const InputDate: React.FC<InputDateProps> = ({
  className,
  format = 'MM/DD/YYYY',
  inputProps,
  onChange,
  ...props
}) => {
  const handleDayChange = (_value, _modifiers, dayPickerInput) => {
    if (onChange) onChange(dayPickerInput.state.value);
  };

  return (
    <DayPickerInput
      classNames={{
        container: clsx('input-date', className),
        overlay: 'input-date__overlay',
        overlayWrapper: 'input-date__overlay-wrapper'
      }}
      overlayComponent={overlayProps => <StyledOverlay {...overlayProps} />}
      format={format}
      formatDate={(value, format) => dayjs(value).format(format)}
      parseDate={(str, format) => {
        const date = dayjs(str, format);
        if (date.isValid()) return date.toDate();
        return new Date();
      }}
      component={Input}
      inputProps={{ icon: 'calendar', ...inputProps }}
      placeholder={format}
      onDayChange={handleDayChange}
      dayPickerProps={{
        showOutsideDays: true,
        navbarElement: Navbar,
        renderDay: Day
      }}
      {...props}
    />
  );
};

// not so pretty way to style datepicker container without wrapping it in another div
export default styled(InputDate)`
  position: relative;

  .input {
    width: 100%;
  }
`;

const StyledOverlay = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  z-index: 1001;
  background: #ffffff;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  padding: 16px 12px;
  margin-top: 8px;

  .DayPicker-Month {
    display: table;
    border-collapse: collapse;
    width: 100%;

    .DayPicker-Caption {
      display: none;
    }

    .DayPicker-Weekdays {
      display: table-header-group;

      .DayPicker-WeekdaysRow {
        display: table-row;
        align-items: center;

        .DayPicker-Weekday {
          display: table-cell;
          font-size: 12px;
          line-height: 22px;
          color: #8d8d8d;
          margin: 0 3px;
          width: 18px;
          text-align: center;
          border-bottom: 1px solid #cdcdcd;
          padding-bottom: 20px;

          abbr {
            text-decoration: none;
          }
        }
      }
    }

    .DayPicker-Body {
      display: table-row-group;
      margin-top: 10px;

      .DayPicker-Week {
        display: table-row;

        &:first-of-type {
          .DayPicker-Day {
            padding-top: 20px;
          }
        }

        .DayPicker-Day {
          display: table-cell;
          text-align: center;
          font-size: 12px;
          line-height: 22px;

          .day {
            height: 30px;
            width: 30px;
            line-height: 30px;
            margin: 2px auto;
            border-radius: 3px;
            cursor: pointer;

            &.past {
              color: #8b8b8b;
            }
            &.outside {
              color: #cdcdcd;
            }
            &.today {
              background: #df1721;
              color: #ffffff;
            }
            &.selected {
              border: 1px solid #df1721;
            }
          }
        }
      }
    }
  }
`;

const StyledNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 17px;

  .next .icon {
    transform: rotate(180deg);
  }
`;

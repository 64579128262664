import slugify from 'slugify';
import { SITE_URL } from 'consts';
import keysToCamel from './keysToCamel';
import documentTypes from './documentTypes.json';

// returns city, state, postal code
export const getListingAddress = (listing: Listing) => {
  return `${[listing.city, listing.state].filter(Boolean).join(', ')} ${listing.postalCode || ''}`;
};

// renames keys to camelCase and adds url
export const mapListings = (listings): Listing[] => {
  const uniqueIds = new Set();
  const uniqueListings: Listing[] = [];

  listings.forEach(item => {
    if (uniqueIds.has(item.id)) {
      // eslint-disable-next-line no-console
      console.warn(`[mapListings]: Duplicate listing id {${item.id}}`);
      return;
    }

    uniqueIds.add(item.id);
    const formattedItem = keysToCamel(item);
    const { transactionDetails, ...rest } = formattedItem;
    uniqueListings.push({
      ...rest,
      url: `${SITE_URL}/listing/${item.id}/${slugify(item.address1, {
        lower: true,
        strict: true
      })}`,
      listingTypeHr: item.listing_type_hr,
      listingDate: new Date(item.listing_date * 1000),
      listingAgent: {
        ...formattedItem.listingAgent,
        email: formattedItem.listingAgent.email.split(';'),
        agency: formattedItem.listingOffice ? formattedItem.listingOffice.name : ''
      },
      transactionDetails: Array.isArray(transactionDetails) ? null : transactionDetails
    });
  });

  return uniqueListings;
};

export const mapListingDocuments = (documents): WorkspaceDocument[] => {
  return documents.map(item => ({
    id: item.id,
    name: item.filename,
    createdBy: item.created_by,
    createdOn: new Date(item.created_on * 1000),
    doctype: item.doctype_id,
    category: documentTypes.find(type => type.id === item.doctype_id)?.category || '',
    folder: item.folder_id,
    listing: item.listing_id,
    link: item.link,
    draft: Boolean(item.is_draft)
  }));
};

export const mapDocumentsTree = (tree: any): AnyNode[] => {
  return tree.map(item => {
    let node: any = {
      id: String(item.id),
      type: item.type,
      active: Boolean(item.active),
      createdBy: item.created_by,
      createdOn: new Date(item.created_on * 1000)
    };
    if (item.type === 'folder')
      node = {
        ...node,
        name: item.folder,
        displayType: 'Folder',
        children: mapDocumentsTree(item.children)
      };
    else
      node = {
        ...node,
        name: item.filename,
        displayType: item.display_type,
        mimeType: item.mime_type,
        draft: Boolean(item.is_draft),
        doctype: item.doctype_id,
        listing: item.listing_id,
        size: item.size
      };
    return node;
  });
};

export const mapWorkspaceNotes = (notes): WorkspaceNote[] =>
  notes.map(item => ({
    id: item.id,
    createdOn: item.created_on * 1000,
    name: item.name,
    email: item.created_by,
    text: item.note
  }));

export const mapWorkspaceActivityLog = items =>
  items.map(item => ({
    id: item.id,
    listingId: item.listing_id,
    createdOn: item.created_on * 1000,
    activityDate: item.activity_date * 1000,
    name: item.name,
    email: item.created_by,
    text: item.activity,
    type: item.type
  }));

export const mapWorkspaceOffer = (item): WorkspaceOffer => {
  return {
    id: item.id,
    buyProcessId: item.buy_process_id,
    active: item.active === 1,
    status: item.status,
    created: item.created,
    formData: item.data,
    offerAmount: item.offerAmount,
    name: item.name,
    link: item.link
  };
};

export const mapVendorOrder = (item): VendorOrder => {
  const documents = item.documents.map(item => ({
    ...keysToCamel(item),
    active: Boolean(item.active),
    createdOn: item.created_on * 1000,
    updatedOn: item?.updated_on * 1000
  }));
  return {
    ...keysToCamel(item),
    active: Boolean(item.active),
    createdOn: item.created_on * 1000,
    updatedOn: item.updated_on * 1000,
    deadline: item.deadline * 1000,
    offeredPrice: Number(item?.offered_price),
    documents
  };
};

export const mapVendorTask = (item): VendorTask => {
  return {
    ...keysToCamel(item),
    active: Boolean(item.active),
    done: Boolean(item.done),
    ordered: Boolean(item.ordered),
    createdOn: item.created_on * 1000,
    updatedOn: item?.updated_on * 1000,
    doneOn: item?.done_on * 1000,
    deadline: item?.deadline * 1000
  };
};

export const mapBroker = ({ id, name, email, phone }) => {
  return { id, name, email, phone };
};

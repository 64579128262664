import useSitedataAssets from 'hooks/useSitedataAssets';
import React from 'react';
import { styled, ThemeWrapper } from 'styles';

const HeaderOnlyLayout = ({ children }) => {
  const { logo } = useSitedataAssets();

  return (
    <ThemeWrapper>
      <StyledHeaderOnlyLayout>
        <header>{logo && <img src={logo} alt="logo" />}</header>
        {children}
      </StyledHeaderOnlyLayout>
    </ThemeWrapper>
  );
};

export default HeaderOnlyLayout;

const StyledHeaderOnlyLayout = styled.div`
  width: 80%;
  margin: auto;

  & > header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 77px;
    border-bottom: 1px solid ${props => props.theme.colors.grayDark};
  }
`;

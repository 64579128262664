import React, { useRef } from 'react';

import { styled } from 'styles';
import { Icon, Input, InputProps } from 'components';
interface FileInputProps extends Omit<InputProps, 'onChange'> {
  ref?: any;
  value?: File;
  inputText?: string;
  onChange?: (file: File | File[]) => void;
  onFileUpload?: (value: File | File[]) => void;
  onFileRemove?: (value: string) => void;
  files?: Array<{ id: string; name: string; }>;
  accept?: string;
}

const FileInput: React.FC<FileInputProps> = React.forwardRef(
  ({ value, inputText, onChange, onFileUpload, onFileRemove, files, accept, ...props }, refProp) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileInputChange = e => {
      const file: File = e.target.files[0];
      if (!file) return;
      fileInputRef.current!.value = '';
      if (onChange) onChange(file);
      if (onFileUpload) onFileUpload(file);
    };

    return (
      <StyledFileInput className="file-input">
        <Input
          icon="fc-file"
          value={inputText || value?.name || ''}
          onClick={() => fileInputRef.current!.click()}
          {...props}
          ref={refProp}
          readOnly
        />
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileInputChange}
          accept={accept}
          style={{ display: 'none' }}
        />
        {files && (
          <div className="file-input__files">
            {files.map(file => (
              <span key={file.id} className="file-input__file">
                <Icon name="fc-file" />
                &nbsp;&nbsp;
                <span className="file-input__file__name">{file.name}</span>&nbsp;&nbsp;
                {onFileRemove && <Icon name="mini-x" size={8} onClick={() => onFileRemove(file.id)} />}
              </span>
            ))}
          </div>
        )}
      </StyledFileInput>
    );
  }
);

export default FileInput;

const StyledFileInput = styled.div`
  .file-input {
    &__file {
      margin: 8px 0 0;
      display: inline-flex;
      align-items: center;
      padding: 4px;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid #000;
      border-radius: ${props => props.theme.misc.borderRadius};
      &__name {
        max-width: 112px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & ~ * {
        margin-left: 8px;
      }
      .icon-mini-x {
        fill: ${props => props.theme.colors.red};
        cursor: pointer;
      }
    }
  }
  .input {
      width: 100%;
    }
`;

import React, { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import clsx from 'clsx';
import { styled } from 'styles';
import useKeyPress from 'hooks/useKeyPress';
import useOutsideClick from 'hooks/useOutsideClick';
import Input, { InputProps } from 'components/Input';

export type AutocompleteOption = {
  id: string | number;
  text: string;
};

export interface InputAutocompleteOptionsProps {
  items?: AutocompleteOption[];
  onOptionSelect: (option: AutocompleteOption) => void;
}

const InputAutocompleteOptions: React.FC<InputAutocompleteOptionsProps> = ({
  items,
  onOptionSelect
}) => {
  const [focus, setFocus] = useState<number>();

  useKeyPress(['ArrowDown', 'ArrowUp'], e => {
    e.preventDefault();
    const options = document.getElementsByClassName('input-autocomplete__option');
    const idxOffset = e.key === 'ArrowDown' ? 1 : -1;
    const optionToFocusIdx = focus === undefined ? 0 : focus + idxOffset;
    const optionToFocus = options[optionToFocusIdx] as HTMLButtonElement;
    if (optionToFocus) {
      optionToFocus.focus();
      setFocus(optionToFocusIdx);
    }
  });

  return (
    <div className="input-autocomplete__options-list">
      {items?.map(item => (
        <button
          className="input-autocomplete__option"
          key={item.id}
          title={item.text}
          onClick={() => onOptionSelect(item)}
          tabIndex={0}>
          {item.text}
        </button>
      ))}
    </div>
  );
};

export interface InputAutocompleteProps extends Omit<InputProps, 'onChange'> {
  containerProps?: React.HTMLAttributes<HTMLInputElement>;
  onChange?: (value: string) => void;
  onOptionSelect?: (option: AutocompleteOption) => void;
  getOptions: (text: string) => Promise<AutocompleteOption[] | undefined>;
}

const InputAutocomplete: React.FC<InputAutocompleteProps> = React.forwardRef(
  ({ className, onChange, getOptions, onOptionSelect, ...props }, refProp) => {
    const containerRef = useRef(null);
    const refHook = useRef(null);
    const ref: any = refProp || refHook;
    const [options, setOptions] = useState<AutocompleteOption[]>();
    const [showOptions, setShowOptions] = useState(false);

    const [fetchOptions] = useDebouncedCallback(async (text: string) => {
      const options = await getOptions(text);
      if (options) {
        setOptions(options);
        setShowOptions(true);
      }
    }, 300);

    useOutsideClick(containerRef.current, () => {
      if (showOptions) setShowOptions(false);
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value.length > 2) fetchOptions(value.toLowerCase());
      onChange?.(value);
    };

    const handleOptionSelect = (option: AutocompleteOption) => {
      if (onChange) onChange(option.text);
      else if (ref.current?.value) ref.current.value = option.text;
      setShowOptions(false);
      onOptionSelect?.(option);
    };

    return (
      <StyledInputAutocomplete
        ref={containerRef}
        className={clsx('input-autocomplete', className)}
        {...props}>
        <Input ref={ref} {...props} onChange={handleInputChange} />
        {showOptions && (
          <InputAutocompleteOptions items={options} onOptionSelect={handleOptionSelect} />
        )}
      </StyledInputAutocomplete>
    );
  }
);

export default InputAutocomplete;

const StyledInputAutocomplete = styled.div`
  display: inline-block;
  position: relative;

  .input {
    width: 100%;
  }

  .input-autocomplete__options-list {
    position: absolute;
    box-sizing: border-box;
    z-index: 1000;
    margin: 4px 0;
    width: 100%;
    max-height: 252px;
    min-height: 32px;
    overflow: auto;
    padding: 0;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
    border-radius: ${props => props.theme.misc.borderRadius};
    background: white;
    list-style: none;

    .input-autocomplete__option {
      font-size: 12px;
      line-height: 18px;
      height: 36px;
      padding: 8px 12px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border: none;
      background: none;
      width: 100%;
      text-align: left;
      outline: none;

      &:hover,
      &:focus {
        color: ${props => props.theme.colors.grayDark};
      }
    }
  }
`;
